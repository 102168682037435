import axiosClient from '../../api/axiosClient';
import { compare, isAuthenticated } from '../../utils';

const CourseDetailModel = {
  state: {},
  reducers: {
    setCourseDetails(state, payload) {
      return { ...payload };
    },
    setLike(state, payload) {
      let temp = { ...state };
      let index = state.assignments.findIndex((data) => data.id === payload.id);
      temp.assignments[index].my_like = !payload.myLike;
      if (!payload.myLike) {
        temp.assignments[index].like_count += 1;
      } else {
        temp.assignments[index].like_count -= 1;
      }
      return temp;
    },
    addComment(state, payload) {
      const { call_type } = payload;
      let temp = { ...state };
      let index = state.assignments.findIndex(
        (data) => data.post_number === payload.post_number
      );
      if (index > -1) {
        let my_comment =
          temp.assignments[index]?.my_comment === true
            ? true
            : call_type !== 'socket';
        if (temp.assignments[index]?.subcomment?.length > 0) {
          temp.assignments[index].subcomment = [
            ...temp.assignments[index].subcomment,
            { ...payload.post }
          ];
        } else {
          temp.assignments[index].subcomment = [{ ...payload.post }];
        }
        temp.assignments[index].my_comment = my_comment;
      }
      return temp;
    },
    addSubComment(state, payload) {
      const { call_type, postId } = payload;

      let temp = { ...state };
      let index = state.assignments.findIndex((data) => data.id === postId);

      let subIndex = state.assignments[index]?.subcomment.findIndex(
        (data) => data.post_number === payload.post_number
      );

      if (subIndex > -1) {
        let my_comment =
          temp.assignments[index]?.subcomment[subIndex].my_comment === true
            ? true
            : call_type !== 'socket';
        if (
          temp.assignments[index]?.subcomment[subIndex]?.childcomment?.length >
          0
        ) {
          temp.assignments[index].subcomment[subIndex].childcomment = [
            ...temp.assignments[index]?.subcomment[subIndex]?.childcomment,
            { ...payload.post }
          ];
        } else {
          temp.assignments[index].subcomment[subIndex].childcomment = [
            { ...payload.post }
          ];
        }
        temp.assignments[index].subcomment[subIndex].my_comment = my_comment;
      }
      return temp;
    },

    addSubChildComment(state, payload) {
      const { call_type, postId, allIndex } = payload;

      let temp = { ...state };
      let index = allIndex?.postIndex;
      let subIndex = allIndex?.commentIndex;
      let childIndex = allIndex?.subCommentIndex;
      if (childIndex > -1) {
        let my_comment =
          temp.assignments[index]?.subcomment[subIndex]?.childcomment[childIndex].my_comment === true
            ? true
            : call_type !== 'socket';
        if (temp.assignments[index]?.subcomment[subIndex]?.childcomment[childIndex]?.subchild?.length > 0) {
          temp.assignments[index].subcomment[subIndex].childcomment[childIndex].subchild = [
            ...temp.assignments[index]?.subcomment[subIndex]?.childcomment[childIndex].subchild,
            { ...payload.post }
          ];
        } else {
          temp.assignments[index].subcomment[subIndex].childcomment[childIndex].subchild = [
            { ...payload.post }
          ];
        }
        temp.assignments[index].subcomment[subIndex].childcomment[childIndex].my_comment = my_comment;
      }
      return temp;
    },

    editComment(state, payload) {
      let temp = { ...state };
      let index = state.assignments.findIndex((data) => data.id === payload.id);
      if (index > -1) {
        let subIndex = state.assignments[index].subcomment.findIndex(
          (data) => data.id === payload.post_id
        );
        if (subIndex > -1) {
          temp.assignments[index].subcomment[subIndex] = {
            ...temp.assignments[index].subcomment[subIndex],
            bio_raw: payload.raw
          };
        }
      }
      return temp;
    },
    editSubComment(state, payload) {
      let temp = { ...state };
      let index = state.assignments.findIndex((data) => data.id === payload.id);
      let subIndex = state.assignments[index]?.subcomment.findIndex(
        (data) => data.id === payload.commentId
      );

      if (subIndex > -1) {
        let subComIndex = state.assignments[index].subcomment[subIndex]?.childcomment.findIndex(
          (data) => data.id === payload.post_id
        );
        if (subComIndex > -1) {
          temp.assignments[index].subcomment[subIndex].childcomment[subComIndex] = {
            ...temp.assignments[index].subcomment[subIndex].childcomment[subComIndex],
            bio_raw: payload.raw
          };
        }
      }
      return temp;
    },

    editSubChildComment(state, payload) {
      let temp = { ...state };
      let index = payload?.allIndex?.postIndex;
      let subIndex = payload?.allIndex?.commentIndex;
      let childIndex = payload?.allIndex?.subCommentIndex
      if (childIndex > -1) {
        let subComIndex = payload.allIndex.subChildIndex;
        if (subComIndex > -1) {
          temp.assignments[index].subcomment[subIndex].childcomment[childIndex].subchild[subComIndex] = {
            ...temp.assignments[index].subcomment[subIndex].childcomment[childIndex].subchild[subComIndex],
            bio_raw: payload.raw
          };
        }
      }
      return temp;
    },

    deleteComment(state, payload) {
      let temp = { ...state };
      let index = state.assignments.findIndex(
        (data) => data.id === payload.post_id
      );
      if (index > -1) {
        let subIndex = state.assignments[index].subcomment.findIndex(
          (data) => data.id === payload.id
        );
        let user_name = payload.user_name;
        if (subIndex > -1) {
          temp.assignments[index].subcomment.splice(subIndex, 1);
          let myComment = temp.assignments[index].subcomment.filter(
            (data) => data.username === user_name
          );
          if (myComment.length === 0 && payload?.call_type !== 'socket') {
            temp.assignments[index].my_comment = false;
          }
        }
      }
      return temp;
    },
    deleteSubComment(state, payload) {
      let temp = { ...state };
      let index = state.assignments.findIndex(
        (data) => data.id === payload.post_id
      );
      if (index > -1) {
        let subIndex = state.assignments[index].subcomment.findIndex(
          (data) => data.id === payload.commentId
        );
        let user_name = payload.user_name;

        if (subIndex > -1) {
          let subComIndex = temp.assignments[index].subcomment[subIndex].childcomment.findIndex(
            (data) => data.id === payload.id
          );
          temp.assignments[index].subcomment[subIndex].childcomment.splice(subComIndex, 1);
          let myComment = temp.assignments[index].subcomment[subIndex].childcomment.filter(
            (data) => data.username === user_name
          );
          if (myComment.length === 0 && payload?.call_type !== 'socket') {
            temp.assignments[index].subcomment[subIndex].my_comment = false;
          }
        }
      }
      return temp;
    },

    deleteSubChildComment(state, payload) {
      let temp = { ...state };
      let index = payload?.allIndex?.postIndex;
      if (index > -1) {
        let subIndex = payload?.allIndex?.commentIndex;
        let user_name = payload.user_name;

        if (subIndex > -1) {
          let subComIndex = payload?.allIndex?.subCommentIndex;
          // temp.assignments[index].subcomment[subIndex].childcomment.splice(subComIndex, 1);
          // let myComment = temp.assignments[index].subcomment[subIndex].childcomment.filter(
          //   (data) => data.username === user_name
          // );
          // if (myComment.length === 0 && payload?.call_type !== 'socket') {
          //   temp.assignments[index].subcomment[subIndex].my_comment = false;
          // }

          if (subComIndex > -1) {
            let subChildIndex = payload?.allIndex?.subChildIndex;

            temp.assignments[index].subcomment[subIndex].childcomment[subComIndex].subchild.splice(subChildIndex, 1);
            let myComment = temp.assignments[index].subcomment[subIndex].childcomment[subComIndex].subchild.filter(
              (data) => data.username === user_name
            );
            if (myComment.length === 0 && payload?.call_type !== 'socket') {
              temp.assignments[index].subcomment[subIndex].childcomment[subComIndex].my_comment = false;
            }
          }


        }
      }
      return temp;
    },

    setPin(state, payload) {
      let temp = [...state.assignments];
      const { pin, id } = payload;
      let index = state.assignments.findIndex((data) => data.id === payload.id);
      if (index > -1) {
        if (!pin) {
          temp.splice(index, 1);
          let fixIndex = state.assignments?.findIndex((data) => data.id === id);
          temp = [{ ...state.assignments[fixIndex], pin: true }, ...temp];
          let adminPinIndex = temp.findIndex((data) => data.admin_pin);
          if (adminPinIndex > -1) {
            temp.splice(adminPinIndex, 1);
            let newIndex = state.assignments.findIndex(
              (data) => data.admin_pin
            );
            temp = [{ ...state.assignments[newIndex] }, ...temp];
          }
        } else {
          temp = [
            ...temp.slice(0, index),
            { ...temp[index], pin: false },
            ...temp.slice(index + 1)
          ];
          let adminPinIndex = temp.findIndex((data) => data.admin_pin);
          let pinData = temp.filter((data) => data.pin);
          let unpinData = temp.filter((data) => !data.pin);
          if (adminPinIndex > -1) {
            temp.splice(adminPinIndex, 1);
            unpinData = temp.filter((data) => !data.pin);
            temp.sort(compare);
            let newIndex = state.assignments.findIndex(
              (data) => data.admin_pin
            );
            temp = [
              { ...state.assignments[newIndex] },
              ...pinData,
              ...unpinData.sort(compare)
            ];
          } else {
            temp.sort(compare);
            temp = [...pinData, ...unpinData.sort(compare)];
          }
        }
      }
      return { ...state, assignments: [...temp] };
    },
    setOutline(state, payload) {
      let temp = { ...state };
      temp.outline = payload.outline;
      temp.is_update = false;
      return temp;
    },
    outlineUpdate(state, payload) {
      let temp = { ...state };
      temp.outline = payload.outline;
      return temp;
    }
  },
  effects: (dispatch) => ({
    async getCourseDetails(id) {
      let response = await axiosClient.get(
        `/${isAuthenticated()
          ? 'preclg_user_course_detail'
          : 'pre_clg_public_course_by_id'
        }/${id}`
      );
      this.setCourseDetails(response.data.data);
    },
    async handleLike(data) {
      axiosClient
        .post(
          `${data.myLike ? '/preclg_dislike_user_post' : '/preclg_like_user_post'
          }`,
          { post_id: data.id }
        )
        .then((res) => {
          if (res.data.status !== 200) {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
      this.setLike(data);
    },
    async handleComment(data, state) {
      const { payload, setPostLoader, setLoader, type, postId, allIndex } = data;
      axiosClient
        .post('/preclg_add_user_comment', payload)
        .then((res) => {
          setPostLoader(null);
          // setLoader(true)
          if (res.data.status === 200) {
            setTimeout(() => {
              setLoader(false)
              let ele = document.getElementById(`${res.data.data.id}`);
              ele?.scrollIntoViewIfNeeded();
              //ele?.getElementsByClassName('public-DraftEditor-content')?.[0]?.focus()
            }, 200)
            const memberIds = state.CourseDetailModel.member_ids?.filter(
              (_) => _ !== state?.SigninModel?.user_name
            );
            const sockData = {
              id: memberIds,
              post_data: {
                post: { post: res.data.data, post_number: payload.post_number,postId },
                course_id: state.CourseDetailModel._id,
                role: state?.SigninModel?.role,
                type:  type === 'ADD_SUB_COMMENT' ? 'presubComment' : type === 'ADD_SUB_CHILD_COMMENT' ? 'presubchildComment' : 'preComment'
              }
            };
            state.SocketModel.socket.emit('add_discussion_post', { sockData });

            switch (type) {
              case 'ADD_SUB_COMMENT':
                this.addSubComment({
                  post: res.data.data,
                  post_number: payload.post_number,
                  postId
                });
                break;

              case 'ADD_SUB_CHILD_COMMENT':
                this.addSubChildComment({
                  post: res.data.data,
                  post_number: payload.post_number,
                  postId,
                  allIndex
                });
                break;

              default:
                this.addComment({
                  post: res.data.data,
                  post_number: payload.post_number
                });
                break;
            }
          } else {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          setPostLoader(null);
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
    },
    async handleEditComment(data, state) {
      let payload = { ...data };
      delete payload.id;
      delete payload?.type;
      delete payload?.commentId;
      delete payload?.allIndex
      axiosClient
        .put('/preclg_edit_user_post', payload)
        .then((res) => {
          if (res.data.status === 200) {
            const memberIds = state.CourseDetailModel.member_ids?.filter(
              (_) => _ !== state?.SigninModel?.user_name
            );
            const sockData = {
              id: memberIds,
              post_data: {
                post: data,
                course_id: state.CourseDetailModel._id,
                type: 'preEditComment'
              }
            };
            state.SocketModel.socket.emit('add_discussion_post', { sockData });
            switch (data.type) {
              case 'EDIT_SUB_COMMENT':
                this.editSubComment(data);
                break;
              case 'EDIT_SUB_CHILD_COMMENT':
                this.editSubChildComment(data);
                break;
              default:
                this.editComment(data);
                break;
            }
          } else {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
    },
    handleDelete(payload, state) {
      const { commentId, type, allIndex, ...rest } = payload;

      axiosClient
        .delete(`/preclg_delete_user_post/${rest.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            const memberIds = state.CourseDetailModel.member_ids?.filter(
              (_) => _ !== state?.SigninModel?.user_name
            );
            const sockData = {
              id: memberIds,
              post_data: {
                post: rest,
                course_id: state.CourseDetailModel._id,
                type: 'preDelete'
              }
            };
            state.SocketModel.socket.emit('add_discussion_post', { sockData });

            switch (type) {
              case 'EDIT_SUB_COMMENT':
                this.deleteSubComment(payload);
                break;
              case 'EDIT_SUB_CHILD_COMMENT':
                this.deleteSubChildComment(payload);
                break;
              default:
                this.deleteComment(payload);
                break;
            }
          } else {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
    },
    async handleUpdateCourse(data) {
      let payload = {
        course_id: data.course_id,
        user_id: data.user_id
      };
      axiosClient
        .put(`preclg_update_course_attendees`, payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setOutline(res.data.data);
            data.closePop(false);
            dispatch.ErrorModel.handleErrorPop({
              header: 'Success',
              body: res.data.message
            });
          } else {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
    },
    handlePinPost(payload) {
      let data = {
        course_id: payload.course_id,
        user_id: payload.user_id,
        post_id: payload.id
      };
      axiosClient
        .put(`${payload.pin ? '/preclg_unpin_post' : '/preclg_pin_post'}`, data)
        .then((res) => {
          if (res.data.status !== 200) {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
      this.setPin(payload);
    },
    async handleUpdateOutline(payload) {
      this.outlineUpdate(payload);
    }
  })
};

export default CourseDetailModel;
